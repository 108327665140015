import React from "react"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"

/** color theme */
const colorPalette = theme.colorPalettes.pink

/** props */
interface Props {
  data: any
}

/** const */
const PageNotFound: React.FC<Props> = (props) => {
  return (
    <Layout title="Page Not Found | Norhart" colorPalette={colorPalette}>
      <div
        className="container-fluid text-center hero"
        style={{
          color: "#FFF",
          background: `linear-gradient(${colorPalette.heroTop} 30%, ${colorPalette.heroBottom} 70%)`,
        }}
      >
        <div className="heroContainer">
          <h3 className="heroTitle p-5">Oh No, Page Not Found!</h3>
        </div>
      </div>
    </Layout>
  )
}

/** export */
/** export */
export default PageNotFound
